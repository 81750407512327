.footer {
    background-color:#F2F1E5;
    color: rgba(0, 0, 0, 0.797);
    padding: 20px 0;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-around;
  }
  
  .footer-column {
    flex: 1;
    margin-left: 80px;
  }
  
  .footer-column h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .footer-column ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-column ul li {
    margin-bottom: 5px;
  }
  
  .disclaimer {
    margin-top: 20px; 
    color: black;
    margin-left: 70px;
    margin-right: 70px;
    font-size: 18px;
    line-height: 25px;
  }
  .footer hr {
    border: 0;
    height: 1px; 
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    background-color: black; 
  }
  .footer p {
    text-align: center;
    margin-top: 20px;
  }