/* styles.css */
.container {
    display: flex;
  }
  
  .left-side {
    flex: 1;
    padding: 100px;
  }
  h2{
    font-size: 40px;
    color: white;
  }
.policy{
  font-size: 30px;
  color: white;
  margin-top: 20px;
}
  .right-side {
    flex: 1;
    padding: 20px;
  }
  .form-area {
    max-width: 600px; 
    margin: 0 auto; 
    background-color: white;
    padding: 40px;
    border-radius: 10px;
  }
  
  .form-row {
    display: flex; 
    gap: 20px;
  }
  
  .form-group {
    flex: 1; 
    margin-top: 10px;
  }
  
  label {
   display: block; 
   font-size:18px;
  }
  
  input[type="text"], [type ="date"], #incident_date{
    width: 100%; 
    height: 40px;
    margin-top: 10px;
    border-radius: 5px;
    border: none;
    border: 1px solid black;
  }
input[type="checkbox"]{
    height: 20px;
    border: 1px solid black;
}
.button-link {
  width: 20px;
  color: red;
  border: none;
  background: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
}
.sub-btn{
  width: 100%;
  height: 45px;
  background-color: #FFB105;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 20px;
}