*{
    margin: 0;
    padding: 0;
}
.header{
    background-color:#F2F1E5;
    border-bottom: 4px solid yellow;
    height: 100px;

}
.logo{
    margin-left: 90px;
}