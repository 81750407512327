/* imgText.css */

.image-text-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 30px 100px; 
}

.text-content {
  flex: 1;
  padding: 20px;
  position: relative;
}

.image {
  width: 50%;
  height: 450px;
}

.text-left {
  flex-direction: row;
}

.text-right {
  flex-direction: row-reverse;
}

.text {
  font-size: 40px; /* Font size for text */
}

.heading {
  font-size: 25px; /* Font size for heading */
}

/* Pink lines background */
.text-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(-45deg, transparent 0%, transparent 40%, pink 40%, pink 60%, transparent 80%, transparent 100%),
    linear-gradient(45deg, transparent 0%, transparent 40%, pink 40%, pink 60%, transparent 60%, transparent 100%),
    linear-gradient(90deg, transparent 0%, transparent 40%, pink 40%, pink 60%, transparent 60%, transparent 100%),
    linear-gradient(-135deg, transparent 0%, transparent 40%, pink 40%, pink 60%, transparent 60%, transparent 100%),
    linear-gradient(135deg, transparent 0%, transparent 40%, pink 40%, pink 60%, transparent 80%, transparent 100%);
  opacity: 0.5; 
  z-index: -1; 
}
