.card {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;

}

.icon {
    font-size: 100px; /* Adjust the font size of the icon */
    margin-right: 20px; /* Add space between the icon and the content */
}

.content {
    flex: 1; /* Allow the content to expand to fill the available space */
}

.heading {
    font-size: 18px; /* Adjust the font size of the heading */
    margin: 0; 
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.description {
    font-size: 30px; 
    color: black;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}
.heartbeat-icon {
    font-size: 3em; /* Increase size */
    color: #1785DA; /* Change color to red */
  }
  